export const environment = {
  apiUrl: 'https://prd.gifrinc.com',
  client_id: '164441e2-3bb5-49b0-b483-04167320019c',
  scope: '164441e2-3bb5-49b0-b483-04167320019c/custom.all',
  redirect_uri: 'https://prd.gifrinc.com/auth-redirect',
  code_verifier: 'RahJB4341r62UPWo4TS0MyoqUceQikt8A2ORIaU2vsasY2_GZThQlWGxfsef5wbW',
  entra_tenant_id: '5517afc8-27df-45bc-a0c5-82ef59dcc5e8',
  entra_tenant_name: 'mhsgears',
  authURL: 'https://mhsgears.ciamlogin.com/mhsgears.onmicrosoft.com/oauth2/v2.0/authorize?client_id=164441e2-3bb5-49b0-b483-04167320019c&nonce=8N_ZPhTlbB&redirect_uri=https://prd.gifrinc.com/auth-redirect&scope=openid%20164441e2-3bb5-49b0-b483-04167320019c/custom.all&response_type=code&prompt=login&code_challenge_method=S256&code_challenge=BjTsl70FjVy_xYiDq_vERlcWraXCA8iG5WD_eai09T4',
  sentryDSN: 'https://o1281049.ingest.us.sentry.io/4507691469701120'
};
